import {city} from "tracabois-platform-realization-domain";
import {city as query} from "tracabois-platform-realization-query-domain";
import {city as stats} from "tracabois-platform-stats-domain";
import {S2StateProp} from "@tracabois/utils";
import {AuthServices} from "@tracabois/connect";
import {AuthServiceAdditional} from "@komune-io/g2-providers";

export type RealizationCreateCommand = city.smartb.tracabois.platform.s2.realization.domain.command.RealizationCreateCommandDTO
export type RealizationCreatedEvent = city.smartb.tracabois.platform.s2.realization.domain.command.RealizationCreatedEventDTO

export type RealizationUpdateCommand = city.smartb.tracabois.platform.s2.realization.domain.command.RealizationUpdateCommandDTO
export type RealizationUpdatedEvent = city.smartb.tracabois.platform.s2.realization.domain.command.RealizationUpdatedEventDTO

export type RealizationDeleteCommand = city.smartb.tracabois.platform.s2.realization.domain.command.RealizationDeleteCommandDTO
export type RealizationDeletedEvent = city.smartb.tracabois.platform.s2.realization.domain.command.RealizationDeletedEventDTO

export type RealizationFinishCommand = city.smartb.tracabois.platform.s2.realization.domain.command.RealizationFinishCommandDTO
export type RealizationFinishedEvent = city.smartb.tracabois.platform.s2.realization.domain.command.RealizationFinishedEventDTO

export type RealizationUnlockCommand = city.smartb.tracabois.platform.s2.realization.domain.command.RealizationFinishCommandDTO
export type RealizationUnlockedEvent = city.smartb.tracabois.platform.s2.realization.domain.command.RealizationFinishedEventDTO

export type RealizationAssignMoeCommand = city.smartb.tracabois.platform.s2.realization.domain.command.RealizationAssignMoeCommandDTO
export type RealizationAssignedMoeEvent = city.smartb.tracabois.platform.s2.realization.domain.command.RealizationAssignedMoeEventDTO

export type RealizationGetQuery = query.smartb.tracabois.platform.f2.realization.query.domain.query.RealizationGetQueryDTO
export type RealizationGetResponse = query.smartb.tracabois.platform.f2.realization.query.domain.query.RealizationGetResponseDTO

export type Realization = query.smartb.tracabois.platform.f2.realization.query.domain.model.RealizationDTO

export type RealizationModel = city.smartb.tracabois.platform.s2.realization.domain.model.RealizationModelDTO
export type RealizationRef = city.smartb.tracabois.platform.s2.realization.domain.model.RealizationRefDTO

export type RealizationCertificationIdLink = city.smartb.tracabois.platform.s2.realization.domain.model.RealizationCertificationIdLinkDTO
export type RealizationCertificationLink = city.smartb.tracabois.platform.s2.realization.domain.model.RealizationCertificationLinkDTO


export type RealizationIndicator = stats.smartb.tracabois.platform.s2.stats.domain.model.RealizationIndicatorsDTO
export type TransformationActor = city.smartb.tracabois.platform.s2.realization.domain.model.TransformationActorDTO

export abstract class RealizationState implements city.smartb.tracabois.platform.s2.realization.domain.automate.RealizationState {
  abstract get name(): "Exists" | "Deleted" | "Finished"
  abstract get ordinal(): 0 | 1 | 2
  abstract get position(): number
}

export const canTerminateRealization = (status?: S2StateProp): boolean => {
  return status?.key == "Exists"
}


export const isEditableRealization = (realization?: RealizationModel | Realization): boolean => {
  // @ts-ignore
  return realization?.status == "Exists"
}

export const isEditableRealizationMoe = (service: AuthServiceAdditional<AuthServices>, realization?: RealizationModel | Realization): boolean => {
  // @ts-ignore
  return realization?.status == "Exists" && service.isMarque()
}

export const canUnlockRealization = (status?: S2StateProp): boolean => {
  return status?.key == "Finished" || status?.key == "Deleted"
}


export const toRealizationS2State = (entity?: RealizationModel | Realization): S2StateProp | undefined => {
  const statue = entity?.status
  // @ts-ignore
  if (statue == "Exists") {
    return {
      key: "Exists",
      name: "En cours",
      color: "info",
    }
  }
  // @ts-ignore
  if (statue == "Finished") {
    return {
      key: "Finished",
      name: "Terminé",
      color: "success"
    }
  } else {
    return {
      key: "Deleted",
      name: "Supprimé",
      color: "error"
    }
  }
}

export type RealizationId = string

export const defaultRealizationIndicator: RealizationIndicator = {
  artIndex: "0",
  co2: 0,
  wood: 0,
  route: 0,
  completion: 10
}

export const productList = (realization: RealizationModel | Realization): string => {
  return realization.actors.map((it) => it.product).join(", ")
}

export const realizationToUpdateRealizationCommand = (realization: Realization): RealizationUpdateCommand => {
  return  {
    id: realization?.id,
    name: realization?.name,
    coordinates: realization?.coordinates,
    artIndex: realization?.indicators?.artIndex,
    redirectPath: realization?.qrcode?.name
  }
}
