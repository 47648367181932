import {
  ProductType,
  ProductTypeCreateCommand,
  ProductTypeCreatedEvent,
  ProductTypeDeleteCommand,
  ProductTypeDeletedEvent,
  ProductTypeId,
  ProductTypePageQuery,
  ProductTypeUpdateCommand,
  ProductTypeUpdatedEvent
} from "../model/ProductType";
import {config} from "../../../config";
import {createApi} from "@reduxjs/toolkit/dist/query/react";
import {allPage, OffsetPagination, PageQuery, PageResult, toPagination} from "@tracabois/utils";
import {useState} from "react";
import {authQuery} from "../../../utils/authQuery";
import {useGetOrganization} from '@komune-io/g2-im';
import { OidcClient } from "@axa-fr/oidc-client";
import { imConfig } from "@komune-io/g2";

export const ProductTypeApi = createApi({
  reducerPath: 'productTypeApi',
  baseQuery: authQuery({baseUrl: config.baseUrl}),
  tagTypes: ['ProductType'],
  endpoints: (builder) => ({
    getProductTypeById: builder.query<ProductType, ProductTypeId>({
      query: (id: ProductTypeId) => ({
        url: `productTypeGet`,
        method: 'POST',
        body: {id: id}
      }),
    }),
    productTypePage: builder.query<PageResult<ProductType>, ProductTypePageQuery>({
      query: (page: ProductTypePageQuery) => ({
        url: `productTypePage`,
        method: 'POST',
        body: page
      }),
      transformResponse: (response: PageResult<ProductType>[]) => response[0],
      providesTags: ['ProductType'],
    }),
    productTypeCreate: builder.mutation<ProductTypeCreatedEvent, ProductTypeCreateCommand>({
      query: (cmd: ProductTypeCreateCommand) => ({
        url: `productTypeCreate`,
        method: 'POST',
        body: cmd
      }),
      transformResponse: (response: ProductTypeCreatedEvent[]) => response[0],
      invalidatesTags: ['ProductType'],
    }),
    productTypeUpdate: builder.mutation<ProductTypeUpdatedEvent, ProductTypeUpdateCommand>({
      query: (cmd: ProductTypeUpdateCommand) => ({
        url: `productTypeUpdate`,
        method: 'POST',
        body: cmd
      }),
      transformResponse: (response: ProductTypeUpdatedEvent[]) => response[0],
      invalidatesTags: ['ProductType'],
    }),
    productTypeDelete: builder.mutation<ProductTypeDeletedEvent, ProductTypeDeleteCommand>({
      query: (cmd: ProductType) => ({
        url: `productTypeDelete`,
        method: 'POST',
        body: cmd
      }),
      transformResponse: (response: ProductTypeDeletedEvent[]) => response[0],
      invalidatesTags: ['ProductType'],
    }),
  }),
})

export const {
  useGetProductTypeByIdQuery, useProductTypePageQuery,
  useProductTypeCreateMutation, useProductTypeUpdateMutation, useProductTypeDeleteMutation
} = ProductTypeApi


export const useProductTypeAllQuery = (organizationId?: string, pagination?: OffsetPagination, rawProduct?: boolean) => {
  const {data, error, isLoading, refetch} = ProductTypeApi.useProductTypePageQuery({})
  const auth = OidcClient.get()
  const token =  auth?.tokens.accessToken
  const organizationResponse = useGetOrganization({
    query: {
      id: organizationId ?? ""
    },
    // @ts-ignore
    apiUrl: imConfig().url,
    queryKey: organizationId,
    jwt: token,
    options: {
      enabled: !!organizationId
    }
  })

  const [pageQuery, setPageQuery] = useState<PageQuery>({
    pagination: pagination || allPage
  })

  let sortedData =  [...data?.items ?? []]
  if(rawProduct != undefined) {
    sortedData = sortedData.filter((item: ProductType) => {
      const isScieur = item.organizationTypes.map(it => it.name).includes('Scieur')
      return (rawProduct && isScieur) || (!rawProduct && !isScieur)
    })
  }
  if(organizationResponse.data != undefined) {
    sortedData = sortedData.filter((item: ProductType) => {
      const itemName = item.organizationTypes.map(it => it.name)
      return organizationResponse.data?.item?.roles?.map ( roleName => {
        return  itemName.includes(roleName)
      }).reduce(
        (previousValue, currentValue) => previousValue || currentValue,
        false
      );
    })
  }

  if(pageQuery.search) {
    sortedData = sortedData.filter((item: ProductType) => {
      return item.name.toLowerCase().includes(pageQuery.search?.toLowerCase() ?? "")
    })
  }
  const page = toPagination(sortedData, pageQuery.pagination)

  return {data: page, error, isLoading, refetch, setPageQuery}
}