import {city} from "tracabois-platform-realizationCertification-domain";

export type RealizationCertificationId = string

export type RealizationCertificationCreateCommand = city.smartb.tracabois.platform.s2.realizationCertification.domain.command.RealizationCertificationCreateCommandDTO
export type RealizationCertificationCreatedEvent = city.smartb.tracabois.platform.s2.realizationCertification.domain.command.RealizationCertificationCreatedEventDTO

export type RealizationCertificationUpdateCommand = city.smartb.tracabois.platform.s2.realizationCertification.domain.command.RealizationCertificationUpdateCommandDTO
export type RealizationCertificationUpdatedEvent = city.smartb.tracabois.platform.s2.realizationCertification.domain.command.RealizationCertificationUpdatedEventDTO

export type RealizationCertificationDeleteCommand = city.smartb.tracabois.platform.s2.realizationCertification.domain.command.RealizationCertificationDeleteCommandDTO
export type RealizationCertificationDeletedEvent = city.smartb.tracabois.platform.s2.realizationCertification.domain.command.RealizationCertificationDeletedEventDTO

export type RealizationCertification = city.smartb.tracabois.platform.s2.realizationCertification.domain.model.RealizationCertificationModelDTO

export const RealizationCertificationState = {
  Exist: city.smartb.tracabois.platform.s2.realizationCertification.domain.automate.RealizationCertificationState.Exists,
  Deleted: city.smartb.tracabois.platform.s2.realizationCertification.domain.automate.RealizationCertificationState.Deleted
}
