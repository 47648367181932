import {city} from "tracabois-platform-productType-domain";

import {OrganizationRef} from "@komune-io/g2-im";

export type ProductTypePageQuery = city.smartb.tracabois.platform.s2.productType.domain.query.ProductTypePageQueryDTO

export type ProductTypeCreateCommand = city.smartb.tracabois.platform.s2.productType.domain.command.ProductTypeCreateCommandDTO
export type ProductTypeCreatedEvent = city.smartb.tracabois.platform.s2.productType.domain.command.ProductTypeCreatedEventDTO

export type ProductTypeUpdateCommand = city.smartb.tracabois.platform.s2.productType.domain.command.ProductTypeUpdateCommandDTO
export type ProductTypeUpdatedEvent = city.smartb.tracabois.platform.s2.productType.domain.command.ProductTypeUpdatedEventDTO

export type ProductTypeDeleteCommand = city.smartb.tracabois.platform.s2.productType.domain.command.ProductTypeDeleteCommandDTO
export type ProductTypeDeletedEvent = city.smartb.tracabois.platform.s2.productType.domain.command.ProductTypeDeletedEventDTO

export type ProductType = city.smartb.tracabois.platform.s2.productType.domain.model.ProductTypeModelDTO

export const ProductTypeState = {
  Exist: city.smartb.tracabois.platform.s2.productType.domain.automate.ProductTypeState.Exists,
  Deleted: city.smartb.tracabois.platform.s2.productType.domain.automate.ProductTypeState.Deleted
}


export const filterByOrganizationId = (productTypes: ProductType[], organizationRefs: OrganizationRef[], organizationId?: string): ProductType[]  => {
  const organizationRefsFound = organizationRefs.find( it => it.id == organizationId)
  const organizationRoles = organizationRefsFound?.roles
  return filterByOrganizationRole(productTypes, organizationRoles)
}
export const filterByOrganizationRef = (productTypes: ProductType[], organizationRef: OrganizationRef): ProductType[]  => {
  const organizationRoles = organizationRef.roles
  return filterByOrganizationRole(productTypes, organizationRoles)
}
export const filterByOrganizationRefs = (productTypes: ProductType[], organizationRefs: OrganizationRef[]): ProductType[]  => {
  const organizationRoles = organizationRefs.flatMap(ref =>   ref.roles)
  return filterByOrganizationRole(productTypes, organizationRoles)
}

const filterByOrganizationRole = (productTypes: ProductType[], roles?: OrganizationRole[]): ProductType[]  => {
  let filteringProductTypes = [...productTypes]
  if(!!roles) {
    filteringProductTypes = filteringProductTypes.filter((item: ProductType) => {
      const itemName = item.organizationTypes.map(it => it.name)
      return roles?.map(roleName => {
        return itemName.includes(roleName)
      }).reduce(
        (previousValue, currentValue) => previousValue || currentValue,
        false
      );
    })
  }
  return filteringProductTypes
}


type OrganizationRole = string

export type ProductTypeId = string
