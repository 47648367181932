import {createApi, fetchBaseQuery} from "@reduxjs/toolkit/dist/query/react";
import { City, Department } from "@tracabois/domain";

export interface CitiesGetResponse {
  code: string
  nom: string
  population: number
}

export interface DepartmentsGetResponse {
  code: string
  nom: string
}

export interface GeoApiCitiesGetQueryParams {
  name: string,
}

export const GeoApi = createApi({
  reducerPath: 'geoApi',
  baseQuery: fetchBaseQuery({baseUrl: "https://geo.api.gouv.fr"}),
  endpoints: (builder) => ({
    getDepartments: builder.query<Department[], any>({
      query: () => ({
        url: `departements`,
        method: 'GET',
        params: {
          fields: 'code,nom'
        }
      }),
      transformResponse: (response: DepartmentsGetResponse[]) => response
      .map(value => {
        const department: Department = {
          code: Number.parseInt(value.code),
          name: value.nom,
        }
        return department
      })
    }),
    getCities: builder.query<City[], any>({
      query: (params: GeoApiCitiesGetQueryParams) => ({
        url: `communes`,
        method: 'GET',
        params: {
          nom: params.name,
          fields: 'code,nom,population'
        }
      }),
      transformResponse: (response: CitiesGetResponse[]) => response
      .filter(value => value.population !== undefined)
      .sort((a, b) => a.population - b.population)
      .reverse()
      .map(value => {
        const commune: City = {
          code: Number.parseInt(value.code),
          name: value.nom,
        }
        return commune
      })
    })
  }),
})

export const {
  useGetDepartmentsQuery,
  useGetCitiesQuery,
} = GeoApi
