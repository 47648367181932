import {LatLngLiteral} from "leaflet";

type Brand = "pyrenees" | "btmc" | "demo" | "lignumcorsica"

export interface BrandCodeConfig {
    code: Brand,
}
export interface BrandConfig {
    title: string,
    acronym: string,
    code: string,
    logo: string,
    website: string,
    certificate: CertificateDisplayConfig,
    colors: {
        primary: string,
        secondary: string,
    },
    map: {
        position: MapPosition
    }
}



export interface CertificateDisplayConfig {
    withBrandName: boolean
}


export interface PlatformConfig {
    baseUrl: string,
    publicUrl: string
}

export interface ImConfig {
    url: string
}



export const brands: { [key in Brand]: BrandConfig }  = {
    pyrenees:  {
        code: "pyrenees",
        title: "Bois des Pyrénées",
        acronym: "BDP",
        logo: `/logo_pyrenees.png`,
        website: "https://www.boisdespyrenees.com/",
        colors: {
            primary: "#5A6430",
            secondary: "#57454A",
        },
        certificate: {
            withBrandName: false
        },
        map: {
            position: {center: {lng: 2, lat: 46}, zoom: 5}
        }
    },
    demo:  {
        code: "demo",
        title: "Bois Local Demo",
        acronym: "BLD",
        logo: `/logo_demo.png`,
        website: "https://www.boisdespyrenees.com/",
        colors: {
            primary: "#5A6430",
            secondary: "#57454A",
        },
        certificate: {
            withBrandName: false
        },
        map: {
            position: {center: {lng: 2, lat: 46}, zoom: 5}
        }
    },
    btmc: {
        code: "btmc",
        title: "Bois des Territoires du Massif Central",
        acronym: "BTMC",
        logo: `/logo_btmc.png`,
        website: "https://www.boisterritoiresmassifcentral.org/",
        colors: {
            primary: "#F37C38",
            secondary: "#57454A",
        },
        certificate: {
            withBrandName: false
        },
        map: {
            position: {center: {lng: 2, lat: 46}, zoom: 5}
        }
    },
    lignumcorsica: {
        code: "lignumcorsica",
        title: "Lignum Corsica",
        acronym: "Lignum Corsica",
        logo: `/logo_lignumcorsica.png`,
        website: "https://www.facebook.com/LignumCorsica/",
        colors: {
            primary: "#bea165",
            secondary: "#352328",
        },
        certificate: {
            withBrandName: true
        },
        map: {
            position: {
                center: {
                    lng: 9.062835948074099,
                    lat: 42.09007006868398
                },
                zoom: 7
            }
        }
    }
}

interface MapPosition {
    center: LatLngLiteral,
    zoom: number
}

export const defaultPosition = {center: {lng: 2, lat: 46}, zoom: 5}


export interface CertificateConfig {
    webUrl: string
}

// @ts-ignore
export const config: PlatformConfig = window._env_.config.platform
// @ts-ignore
export const imConfig: ImConfig = window._env_.config.i2
// @ts-ignore
export const brandCodeConfig: BrandCodeConfig = window._env_.config.brand

export const brandConfig = (): BrandConfig =>  {
    return brands[brandCodeConfig.code] ?? brands["btmc"];
}

// @ts-ignore
export const certificateConfig: CertificateConfig = window._env_.config.certificate
