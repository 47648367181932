import {createApi} from "@reduxjs/toolkit/dist/query/react";
import {PageQuery, PageResult} from "@tracabois/utils";
import {
  BtmcOrganization,
  BtmcOrganizationAssignBtmcNumberCommand,
  BtmcOrganizationAssignedBtmcNumberEvent,
  BtmcOrganizationGetQuery,
  BtmcOrganizationGetResponse,
  BtmcSubOrganizationAddCommand,
  BtmcSubOrganizationAddedEvent,
  BtmcSubOrganizationDeleteCommand,
  BtmcSubOrganizationDeletedEvent,
  BtmcSubOrganizationUpdateCommand,
  BtmcSubOrganizationUpdatedEvent
} from "../model";
import {authQuery} from "../../../utils/authQuery";
import {config} from "../../../config";
import {OrganizationId} from "@komune-io/g2-im";

export const getOrganizationLogoUrl = (id: OrganizationId) => {
  return `${config.baseUrl}/organizations/${id}/logo`
}
export const BtmcOrganizationApi = createApi({
  reducerPath: 'btmcOrganizationApi',
  baseQuery: authQuery(),
  endpoints: (builder) => ({
    getBtmcOrganizationById: builder.query<BtmcOrganization | undefined, BtmcOrganizationGetQuery>({
      query: (body: BtmcOrganizationGetQuery) => ({
        url: `${config.baseUrl}/btmcOrganizationGet`,
        method: 'POST',
        body: body
      }),
      transformResponse: (response: BtmcOrganizationGetResponse[]) => response[0]?.item as BtmcOrganization,
    }),
    getBtmcOrganizationsPage: builder.query<PageResult<BtmcOrganization>, PageQuery>({
      query: (page: PageQuery) => ({
        url: `${config.baseUrl}/btmcOrganizationPage`,
        method: 'POST',
        body: page,
      }),
      transformResponse: (response: PageResult<BtmcOrganization>[]) => response[0],
    }),

    btmcSubOrganizationAdd: builder.mutation<BtmcSubOrganizationAddedEvent, BtmcSubOrganizationAddCommand>({
      query: (cmd: BtmcSubOrganizationAddCommand) => ({
        url: `${config.baseUrl}/btmcSubOrganizationAdd`,
        method: 'POST',
        body: cmd
      }),
      transformResponse: (response: BtmcSubOrganizationAddedEvent[]) => response[0],
    }),
    btmcSubOrganizationUpdate: builder.mutation<BtmcSubOrganizationUpdatedEvent, BtmcSubOrganizationUpdateCommand>({
      query: (cmd: BtmcSubOrganizationUpdateCommand) => ({
        url: `${config.baseUrl}/btmcSubOrganizationUpdate`,
        method: 'POST',
        body: cmd
      }),
      transformResponse: (response: BtmcSubOrganizationUpdatedEvent[]) => response[0],
    }),
    btmcSubOrganizationDelete: builder.mutation<BtmcSubOrganizationDeletedEvent, BtmcSubOrganizationDeleteCommand>({
      query: (cmd: BtmcSubOrganizationDeleteCommand) => ({
        url: `${config.baseUrl}/btmcSubOrganizationDelete`,
        method: 'POST',
        body: cmd
      }),
      transformResponse: (response: BtmcSubOrganizationDeletedEvent[]) => response[0],
    }),
    btmcOrganizationAssignBtmcNumber: builder.mutation<BtmcOrganizationAssignedBtmcNumberEvent, BtmcOrganizationAssignBtmcNumberCommand>({
      query: (cmd: BtmcOrganizationAssignBtmcNumberCommand) => ({
        url: `${config.baseUrl}/btmcOrganizationAssignBtmcNumber`,
        method: 'POST',
        body: cmd
      }),
      transformResponse: (response: BtmcOrganizationAssignedBtmcNumberEvent[]) => response[0],
    }),
  }),
  refetchOnMountOrArgChange: true
})

export const {
  useGetBtmcOrganizationByIdQuery,
  useGetBtmcOrganizationsPageQuery,
  useBtmcSubOrganizationAddMutation,
  useBtmcSubOrganizationUpdateMutation,
  useBtmcSubOrganizationDeleteMutation,
  useBtmcOrganizationAssignBtmcNumberMutation,
} = BtmcOrganizationApi
