import {city} from "tracabois-platform-removalZoneCertification-domain";

export type RemovalZoneCertificationId = string

export type RemovalZoneCertificationCreateCommand = city.smartb.tracabois.platform.s2.removalZoneCertification.domain.command.RemovalZoneCertificationCreateCommandDTO
export type RemovalZoneCertificationCreatedEvent = city.smartb.tracabois.platform.s2.removalZoneCertification.domain.command.RemovalZoneCertificationCreatedEventDTO

export type RemovalZoneCertificationUpdateCommand = city.smartb.tracabois.platform.s2.removalZoneCertification.domain.command.RemovalZoneCertificationUpdateCommandDTO
export type RemovalZoneCertificationUpdatedEvent = city.smartb.tracabois.platform.s2.removalZoneCertification.domain.command.RemovalZoneCertificationUpdatedEventDTO

export type RemovalZoneCertificationDeleteCommand = city.smartb.tracabois.platform.s2.removalZoneCertification.domain.command.RemovalZoneCertificationDeleteCommandDTO
export type RemovalZoneCertificationDeletedEvent = city.smartb.tracabois.platform.s2.removalZoneCertification.domain.command.RemovalZoneCertificationDeletedEventDTO

export type RemovalZoneCertification = city.smartb.tracabois.platform.s2.removalZoneCertification.domain.model.RemovalZoneCertificationModelDTO

export const RemovalZoneCertificationState = {
  Exist: city.smartb.tracabois.platform.s2.removalZoneCertification.domain.automate.RemovalZoneCertificationState.Exists,
  Deleted: city.smartb.tracabois.platform.s2.removalZoneCertification.domain.automate.RemovalZoneCertificationState.Deleted
}
