import {Stack} from "@mui/material";
import { ExternalFile, externalFileUrl } from "@tracabois/domain";
import React from "react";


export interface CertificateBodyProps {
  children?: React.ReactNode;
  image?: ExternalFile
}

export const CertificateBody = (props: CertificateBodyProps) => {
  const {children, image} = props
  const childrenStack = (
    <Stack 
      direction="column"
      flexWrap="wrap"
      justifyContent="space-evenly"
      zIndex="2">
      {children}
    </Stack>
  )
  if (!image) return childrenStack
  return (
    <Stack
      paddingTop="300px"
      position="relative"
    >
      <Stack 
        width='100%' 
        height='350px'
        position='absolute' 
        top="0" 
        overflow='hidden'
        zIndex="1"
      >
        <img src={externalFileUrl(image)}/>
      </Stack>
      {childrenStack}
    </Stack>
  )
}